(() => {
  const loadScript = (src, attributes) => {
    const scriptEl = document.createElement('script');
    scriptEl.src = src;
    const keys = Object.keys(attributes);
    for (var i = 0; i < keys.length; i++) {
      scriptEl.setAttribute(keys[i], attributes[keys[i]]);
    }
    document.head.append(scriptEl);
  }

  const scriptEl = document.currentScript;
  window._webcomet = window._webcomet || {};

  if (! window._webcomet.componentScriptsLoaded) {
    const parser = document.createElement('a');
    parser.href = scriptEl.src;

    const url = `//${parser.host}/webcomet-components/`;
    loadScript(`${url}webcomet-components.esm.js`, { type: 'module' });
    loadScript(`${url}webcomet-components.js`, { nomodule: '' });

    window._webcomet.componentScriptsLoaded = true;
  }

  if (! scriptEl.dataset.component) {
    console.error(`[webcomet]: Missing attribute 'data-component' on script tag`);
    return;
  }

  if (! scriptEl.dataset.faqId) {
    console.error(`[webcomet]: Missing attribute 'data-faq-id' on script tag`);
    return;
  }

  const ignoreAttributes = ['component'];
  const toKebabCase = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase());

  const componentEl = document.createElement(scriptEl.dataset.component);
  for (const attr in scriptEl.dataset) {
    if (ignoreAttributes.includes(attr)) {
      continue;
    }

    componentEl.setAttribute(toKebabCase(attr), scriptEl.dataset[attr]);
  }

  if (scriptEl.dataset.appendToBody === 'true') {
    document.addEventListener('DOMContentLoaded', function() {
      document.body.appendChild(componentEl);
    });
  } else {
    scriptEl.parentNode.appendChild(componentEl);
  }

  if (scriptEl.dataset.readyCallback) {
    const get = require('lodash/get');

    const callback = get(window, scriptEl.dataset.readyCallback);

    if (callback === undefined || typeof callback !== 'function') {
      console.error(`[webcomet]: Attribute 'data-ready-callback' could not be found or is not a function`);
      return;
    }

    componentEl.addEventListener('faqReady', () => {
      callback(componentEl);
    });
  }
})();